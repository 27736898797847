// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAQNxYKkaLwBIigUTT5hVlt6l8x-4IFXRQ",
  authDomain: "lucknow-darbar.firebaseapp.com",
  projectId: "lucknow-darbar",
  storageBucket: "lucknow-darbar.appspot.com",
  messagingSenderId: "711960060308",
  appId: "1:711960060308:web:923f9d4dcd73abe9e52f81"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export const firestore = getFirestore(app);