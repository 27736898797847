import React from 'react';
import { Fade } from "react-awesome-reveal";
import floral from '../Images/floral.png';
import HeaderMain from './HeaderMain';
import { CgProfile } from "react-icons/cg";
import { firestore } from '../firebase';
import menuData from '../Components/menu.json';

const MenuComponent = () => {
  const showOrHideDiv = (id) => {
    var x = document.getElementById(id);
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };
  function isNumber(value) {
    return typeof value === 'number' && !isNaN(value);
  }

  return (
    <>
      <HeaderMain />
      {/* <CgProfile /> */}

      <div className="container-fluid pt-4">
        <div className="d-flex justify-content-center align-items-center pt-5">
          <img src={floral} alt="" className='floral' />
        </div>

        <div className="d-flex justify-content-center align-items-center mt-4">
          <div>
            <Fade triggerOnce>
              <div className="d-flex">
                <p className='dash'>___________</p>
                <p className='simply-the-best mx-2'>Variety And Flavor</p>
                <p className='dash'>___________</p>
              </div>
            </Fade>
          </div>
        </div>

        <div className="d-flex justify-content-center align-items-center">
          <Fade triggerOnce><p className="head-about">OUR AWARDED MENU</p></Fade>
        </div>
        <div className="container mx-lg-5 px-5 pb-5 d-flex justify-content-center">
          <Fade triggerOnce> 
            <p className='mx-lg-5 px-lg-5 text-center text-white about-text'>
              Explore the culinary tapestry at Tasty Lane, where our menu unfolds a rich symphony of flavors, each dish a masterpiece of taste and innovation. From enticing starters to indulgent desserts, every item is a carefully curated delight that promises a journey of gastronomic bliss.
            </p>
          </Fade>
        </div>

        <div className="container pb-5">
          <div className="row">
            {/* First Menu Card */}
            <div className='d-flex justify-center pt-3'>
            <p>Starter</p>
            </div>




            <div className="border-2 col-md-6 container-xxl menu">
              {menuData.MenuData && menuData.MenuData.menu.map((category) => (
                <div key={category.category}>
                  <div className="d-flex" onClick={() => showOrHideDiv(`menu1-${category.category}`)}>
                    <p className='menu-name pt-2'>{category.category}</p>
                    <p className='dropdown-style ms-auto pt-2'>
                      <i className="fa-solid fa-angle-down"></i>
                    </p>
                  </div>
                  <div id={`menu1-${category.category}`} className='px-4 pt-2 pb-4' style={{ display: 'none' }}>
                    {category.items.map((item) => (
                      <div key={item.id} className="d-flex justify-content-between">
                        <p className='menu-item' style={{width:'160px'}}>{item.name}</p>
                     { item && item.halfprice &&  <p className='menu-price' style={{width:'71px'}}>₹{isNumber(item.halfprice) ? item.halfprice.toFixed(2) : item.halfprice }</p>}
                        <p className='menu-price' style={{width:'71px'}}>₹{isNumber(item.price) ? item.price.toFixed(2) : item.price }</p>
                      </div>
                    ))}
                  </div>
                  <hr className='hr-line' />
                </div>
              ))}
            </div>

            {/* Second Menu Card */}
            <div className='d-flex justify-center pt-3'>

            <p>Main Course</p>
            </div>
            <div className="border-2 col-md-6 container-xxl menu">
              {menuData.MenuData2 && menuData.MenuData2.menu.map((category) => (
                <div key={category.category}>
                  <div className="d-flex" onClick={() => showOrHideDiv(`menu2-${category.category}`)}>
                    <p className='menu-name pt-2'>{category.category}</p>
                    <p className='dropdown-style ms-auto pt-2'>
                      <i className="fa-solid fa-angle-down"></i>
                    </p>
                  </div>
                  <div id={`menu2-${category.category}`} className='px-4 pt-2 pb-4' style={{ display: 'none' }}>
                    {category.items.map((item) => (
                      <div key={item.id} className="d-flex justify-content-between">
                        <p className='menu-item' style={{width:'160px'}}>{item.name}</p>
                        { item && item.halfprice &&  <p className='menu-price' style={{width:'71px'}}>₹{isNumber(item.halfprice) ? item.halfprice.toFixed(2) : item.halfprice }</p>}
                        <p className='menu-price' style={{width:'71px'}}>₹{isNumber(item.price) ? item.price.toFixed(2) : item.price }</p>
                      </div>
                    ))}
                  </div>
                  <hr className='hr-line' />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MenuComponent;
