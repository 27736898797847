import React, { useState } from 'react';
import { Container, Navbar, Form, Button } from 'react-bootstrap';
import img from '../Images/logo.png';
import { Link } from 'react-router-dom';
import { CgProfile } from 'react-icons/cg';

function HeaderMain() {
  const predefinedPassword = 'newmenu';
  const [password, setPassword] = useState('');
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [showAddCategoryForm, setShowAddCategoryForm] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [newItemName, setNewItemName] = useState('');
  const [newItemPrice, setNewItemPrice] = useState('');

  const handleProfileIconClick = () => {
    setShowPasswordInput(true);
  };

  const handlePasswordSubmit = () => {
    if (password === predefinedPassword) {
      // Password matches
      console.log('Password matched!');
      // Add your logic here for further actions if needed
      
      // Reset the password and hide the input box
      setPassword('');
      setShowPasswordInput(false);
      setShowAddCategoryForm(true);
    } else {
      // Password does not match
      console.log('Password does not match!');
      // Add your logic here for incorrect password scenario
    }
  };
  const handleAddCategorySubmit = () => {
    // Add your logic here to handle the submitted data for adding a new category
    console.log('Category added!');
    // You can reset the form and hide it if needed

    // Reset the form and hide it
    setShowAddCategoryForm(false);
  };

  return (
    <div>
      <Navbar className="bg-secondary h-16">
        <Container>
          <Navbar.Brand>
            <Link to="/" className='text-decoration-none'>
              <h4 className='flex items-center mt-2'>
                <img className='' style={{ height:'70px'}} src={img} alt="" />
                <span className='ms-2 font-extrabold text-black'>Lucknow Darbar</span>
              </h4>
            </Link>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <CgProfile size={30} onClick={handleProfileIconClick} />
          </div>
        </Container>
      </Navbar>

      {showPasswordInput && (
        <Container className="mt-4">
          <Form className='w-25'>
            <Form.Group className="mb-3">
              <Form.Label>Password:</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Button variant="primary" onClick={handlePasswordSubmit}>
              Submit Password
            </Button>
          </Form>
        </Container>
      )}
      {showAddCategoryForm && (
        <Container className="mt-4">
          <Form className='w-25'>
            {/* Add your form fields for adding a new category */}
            <Form.Group className="mb-3">
              <Form.Label>New Category:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter new category"
                // Add necessary props for handling the input value
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Item Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter item name"
                value={newItemName}
                onChange={(e) => setNewItemName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Item Price:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter item price"
                value={newItemPrice}
                onChange={(e) => setNewItemPrice(e.target.value)}
              />
            </Form.Group>

            <Button variant="primary" onClick={handleAddCategorySubmit}>
              Add New
            </Button>
          </Form>
        </Container>
      )}
    </div>
  );
}

export default HeaderMain;
